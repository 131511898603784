import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import Nav from "../components/Nav";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;
const BlogPost = styled.div`
  background-color: white;
  padding: 20px;
  margin: 20px auto;
  max-width: 90%;
`;

export default function SingleBlogPage({ data }) {
  return (
    <Wrapper>
      <Nav />
      <BlogPost>
        <h1>{data.sanityBlog.title}</h1>
        <h1>{data.sanityBlog.date}</h1>
        <BlockContent blocks={data.sanityBlog._rawBody} />
      </BlogPost>
    </Wrapper>
  );
}

export const query = graphql`
  query($slug: String!) {
    sanityBlog(slug: { current: { eq: $slug } }) {
      title
      date
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;
